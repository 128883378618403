.coloredDataElement {
    background-color: #d8dfe9;
    padding: 3px 5px;
}
.img-container {
    background: $primary-color;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
        color: #fff;
        height: 50px;
        width: 50px;
    }
}
